@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&display=swap');

body {
  @apply font-sans;
}
:root {
  --color-primary: #061228;
  --color-secondary: #50808c;
  --color-main: #e9c369;
  --color-base: #ffffff;
  --color-important: #50808c;
  --color-span: #919191;
  --color-textspan: #9a9ea6;
  --color-dropdown: #f0f4fa;
  --color-bg: #0d0d0d;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
